* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  text-decoration: none; 

}

body{
  font-family: 'Courier New', Courier, monospace;
  scroll-behavior: smooth;
  background-color: #ececec;
}



/* menu button */
.menu_btn{
  height: 45px;
  width: 45px;
  border-radius: 15px;
  background-color: #490000;
  margin: 2px 0 0 10px;
  position: fixed;
  box-shadow: #000000 5px 5px 3px;
  z-index: 3;
}

.menu_btn:active{
  box-shadow:  none;
  transform: scale(0.7) ;
}

.menu_btn:hover{
  background-color: #aa0000;
  transition: background-color 0.5s;
  box-shadow: #000000 5px 5px 8px;
}

.menu_Close_btn{
  height: min-content;
  width: fit-content;
  background-color: #490000;
  border-radius: 5px;
  margin: 8% 0 5% 60%;
  padding: 2%;
  box-shadow: #000000 2px 2px 3px;
}

.menu_Close_btn:hover{
  background-color: #aa0000;
  transition: background-color 0.25;
  box-shadow: #000000 5px 5px 8px;
  transform: scale(1.2) ;
}

.menu_Close_btn:active{
  box-shadow:  none;
  transform: scale(0.7) ;
}



/* side nav bar */
.sidenav_collapsed{
  background: linear-gradient(90deg, rgba(124,0,0,1) 0%, rgba(106,5,5,1) 100%);
  height:100%;
  width:65px;
  float: left;
  flex: 1;
  position:fixed;
  transition: all 0.3s cubic-bezier(.17,.67,.43,1.19);
  z-index: 2;
  left: 0;
  border-top-right-radius: 95px;
  /* border-bottom-right-radius: 95px; */
}

.sidenav {
  background: linear-gradient(90deg, rgba(124,0,0,1) 0%, rgba(106,5,5,1) 100%);
  z-index: 4;
  height:100%;
  left: -100%;
  width:180px;
  position:fixed;
  transition: all 0.1s cubic-bezier(.01,.83,.58,.85);
  border-top-right-radius: 100px;
  /* border-bottom-right-radius: 100px; */
}


.sidenav.active{
  left: 0%;
  
}

.sidenav ul li a{
  text-decoration: none;
  font-size:larger;
  color:#efefef;
  
}


.sidenav ul{
  padding: 5%;
  margin-top: 18%;
}

.sidenav ul li {
  list-style-type:none;
  font-weight:lighter;
  padding: 20px;
  margin-top: 20%;
  display: block;
  background-color: #6b0000c9;
}

.sidenav_collapsed ul li{
  list-style-type:none;
  font-weight:lighter;
  padding: 20px 10px;
  margin-top: 90%;
  display: block;
  background-color: #6b0000c9;
}

.sidenav_collapsed ul{
  padding: 5%;
  margin-top: 120%;
  border-bottom: #444444;
}



#home:hover{
  background: linear-gradient(90deg, rgba(255,255,0,1) 0%, rgba(4,106,0,1) 100%);
  animation: btn-effects-home 0.15s ease-in-out ;
  
  
}

#prj:hover{
  background: linear-gradient(270deg, rgba(138,70,252,1) 0%, rgba(255,0,0,1) 100%); 
  animation: btn-effects-prj 0.15s ease-in-out ;
}

#abt:hover{
  background: linear-gradient(90deg, #7e34ff 0%, rgb(0, 110, 255) 100%);
  animation: btn-effects-abt 0.15s ease-in-out ; 
}


#home:hover *, #prj:hover *, #abt:hover *{
  color: rgb(0, 0, 0);
  transition: all 0.5s ease-in-out;
  border-radius: 15px;
  
}

#home:hover , #prj:hover, #abt:hover{
  transition: all 0.5s ease-in-out;
  border-radius: 15px;
}


/* linear gradient*/
@keyframes btn-effects-home {
  0%{background: transparent;}
  25%{background: linear-gradient(90deg, rgba(255,255,0,1) 0%, rgba(4,106,0,1) 25%); }
  50%{background: linear-gradient(90deg, rgba(255,255,0,1) 0%, rgba(4,106,0,1) 50%);}
  75%{background: linear-gradient(90deg, rgba(255,255,0,1) 0%, rgba(4,106,0,1) 75%);}
  100%{background: linear-gradient(90deg, rgba(255,255,0,1) 0%, rgba(4,106,0,1) 100%); }
}

/*reverse linear gradient*/
@keyframes btn-effects-prj {
  0%{background: transparent;}
  25%{background: linear-gradient(270deg, rgba(138,70,252,1) 0%, rgba(255,0,0,1) 25%); }
  50%{background: linear-gradient(270deg, rgba(138,70,252,1) 0%, rgba(255,0,0,1) 50%);}
  75%{background: linear-gradient(270deg, rgba(138,70,252,1) 0%, rgba(255,0,0,1) 75%);}
  100%{background: linear-gradient(270deg, rgba(138,70,252,1) 0%, rgba(255,0,0,1) 100%); }
}

/*circular gradient*/
@keyframes btn-effects-abt {
  0%{background: transparent;}
  25%{background: radial-gradient(circle, rgba(114,31,252,1) 0%, rgba(2,223,207,1) 25%); }
  50%{background: radial-gradient(circle, rgba(114,31,252,1) 0%, rgba(2,223,207,1) 50%);}
  75%{background: radial-gradient(circle, rgba(114,31,252,1) 0%, rgba(2,223,207,1) 75%);}
  100%{background: radial-gradient(circle, rgba(114,31,252,1) 0%, rgba(2,223,207,1) 100%); }
}



.navIcon{
  padding: 0px 10px;
  color:#efefef;
}

.navIcon:hover {
  color: rgb(0, 0, 0) ;
}


.page{
  margin:0px 10px 0 75px;
}


/* Responsiveness */
@media screen and (max-width: 900px) {
  *{
    border-radius: 0px;
  }
  .sidenav_collapsed {
    left: -100px;
  }

  .page{
    margin: 0px;
  }

  .menu_btn{
    height: 35px;
    width: 60px;
    border-radius: 0 15px 15px 0;
    background-color: #490000;
    margin: 15px 0 0 0px;
    box-shadow: #000000 1px 1px 3px;
    z-index: 3;
  }

  .menu_btn:active{
    transform: scale(1);
  }
}





/* export to the relative file */
/* social media links */
#sm{
  display: flex;
  /* align the links to left vertical */
  background-color: red;
  width: 100%;
  position: fixed;
  left:0;
  bottom: 0;
  z-index: 100;
}